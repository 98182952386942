@import "@/styles/colors.module";

.label {
  color: $light_gray;
  font-size: 14px;
  line-height: 22px;
}

.colon {
  display: inline-block;
  margin-left: 1px;
}

.hint {
  margin-left: 8px;
  height: 16px;
}