@import '@/styles/colors.module';
@import "@/styles/mixins";

.chips {
  @include truncate;
  background-color: $border_gray;
  padding: 4px;
  border-radius: 20px;
  font-size: 14px;
  text-align: right;
  font-weight: 400;
  height: 21px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  width: fit-content;

  &.applicable {
    background-color: $light_blue_2;

    &.red {
      background: $raspberry_light;
    }

    &.orange {
      background: $orange_light;
    }

    &.green {
      background: $green_light;
    }
  }

  &Text {
    height: 18px !important;
    line-height: 18px !important;
    font-size: inherit !important;
    color: inherit !important;
    font-weight: inherit !important;
  }
}